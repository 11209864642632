import Profile from "../pages/Profile";
import FAQ from "../pages/FAQ";

const content = [
  { title: "AT-SA Practice", componenet: null, path: '/simulator'},
  { title: "FAQ", component: <FAQ /> },
  { title: "Profile", component: <Profile /> },
];

export default content;
