import React from "react";
import { Theme } from "@mui/system";

import {
  Box,
  Button,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";

import sideMenuItems from "../config/sideMenuItems";
import HeaderBar from "./HeaderBar";
import { apiClient } from "../api/apiClient";

interface MainContentProps {
  selectedMenu: number;
}

const MainContent: React.FC<MainContentProps> = ({ selectedMenu }) => {
  const [leaderboardData, setLeaderboardData] = React.useState<any>(null);

  let url =
    "https://roger-that-e17b66fe09c9.herokuapp.com/apiapp/users/leaderboard/";
  if (process.env.NODE_ENV === "test") {
    url = "http://127.0.0.1:8000/apiapp/users/leaderboard/";
  }

  React.useEffect(() => {
    apiClient(url, null, "GET").then((data) => setLeaderboardData(data));
  }, []);

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const styles = {
    padding: isSmallScreen ? "1em" : "2em",
    margin: isSmallScreen ? 0 : "auto",
    maxWidth: "800px",
    flex: 1,
  };

  const onClick = () => {
    document.location.href = "/simulator";
    console.log("Play Simulator Clicked");
  };

  const content = sideMenuItems[selectedMenu];
  return (
    <div style={styles}>
      <HeaderBar />
      <h2>{content.title}</h2>
      {content.component || (
        <>
          <>
            <p>
              Work on your collision simulator skills every day to get prepped
              for the AT-SA.
            </p>
            <Box sx={{ textAlign: "center", marginTop: "1em" }}>
              <Button variant="text" onClick={onClick} color="primary">
                {"Lets Play"}
              </Button>
            </Box>
          </>
          <>
            <h2>Leaderboard</h2>

            {leaderboardData ? (
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow style={{}}>
                      <TableCell
                        style={{
                          color: "white",
                          fontSize: 24,
                          fontWeight: "bold",
                        }}
                      >
                        Rank
                      </TableCell>
                      <TableCell
                        style={{
                          color: "white",
                          fontSize: 24,
                          fontWeight: "bold",
                        }}
                      >
                        User
                      </TableCell>
                      <TableCell
                        style={{
                          color: "white",
                          fontSize: 24,
                          fontWeight: "bold",
                        }}
                      >
                        Score
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leaderboardData.leaderboard.map(
                      (user: any, index: number) => {
                        let backgroundColor = index % 2 ? "gray" : "darkgray";
                        let rowStyle: any = {
                          backgroundColor: backgroundColor,
                        };
                        let cellStyle: any = {
                          fontWeight: "bold",
                        };
                        if (index === 9) {
                          rowStyle = {
                            ...rowStyle,
                            borderBottom: "10px solid black",
                          };
                        }

                        if (
                          user.username ===
                          leaderboardData.current_user.username
                        ) {
                          rowStyle = {
                            border: "5px solid black",
                            backgroundColor: backgroundColor,
                          };
                          cellStyle = {
                            fontWeight: "bold",
                            fontSize: "18px",
                          };
                        }

                        return (
                          <TableRow key={user.username} style={rowStyle}>
                            <TableCell
                              component="th"
                              scope="user"
                              style={cellStyle}
                            >
                              {user.ranking}
                            </TableCell>
                            <TableCell style={cellStyle}>
                              {user.username}
                            </TableCell>
                            <TableCell style={cellStyle}>
                              {user.high_score}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <p>No scores yet! Check back later.</p>
            )}
          </>
        </>
      )}
    </div>
  );
};

export default MainContent;
