import React, { useState, useEffect } from "react";
import { getCurrentStep } from "./signUpApi";
import { redirect } from "react-router-dom";

/* Steps */
import SignUpStep1 from "./SignUpStep1";
import SignUpStep2 from "./SignUpStep2";
import SignUpStep3 from "./SignUpStep3";
import SignUpStep4 from "./SignUpStep4";
import SignUpStep5 from "./SignUpStep5";
import SignUpStep6 from "./SignUpStep6";

const SignUpFlowWrapper: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<string | null>(null);

  useEffect(() => {
    const loadCurrentStep = async () => {
      const step: string = await getCurrentStep();
      setCurrentStep(step);
    };
    loadCurrentStep();
  }, []);

  const goToNextStep = () => {
    let nextStep;
    if (currentStep === "step1") {
      if (localStorage.getItem("userWasPreviouslyCreated")) {
        nextStep = "step4";
      } else {
        nextStep = "step2";
      }
    } else if (currentStep === "step2") {
      nextStep = "step3";
    } else if (currentStep === "step3") {
      nextStep = "step4";
    } else if (currentStep === "step4") {
      if (localStorage.getItem("userWasPreviouslyCreated")) {
        nextStep = null;
      } else {
        nextStep = "step5";
      }
    } else if (currentStep === "step5") {
      nextStep = "step6";
    } else {
      nextStep = null;
    }
    setCurrentStep(nextStep);

    if (nextStep === null) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <div>
      {currentStep === "step1" && <SignUpStep1 onNextStep={goToNextStep} />}
      {currentStep === "step2" && <SignUpStep2 onNextStep={goToNextStep} />}
      {currentStep === "step3" && <SignUpStep3 onNextStep={goToNextStep} />}
      {currentStep === "step4" && <SignUpStep4 onNextStep={goToNextStep} />}
      {currentStep === "step5" && <SignUpStep5 onNextStep={goToNextStep} />}
      {currentStep === "step6" && <SignUpStep6 onNextStep={goToNextStep} />}
    </div>
  );
};

const LoginFlowWrapper: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<string | null>(null);

  useEffect(() => {
    const loadCurrentStep = async () => {
      const step: string = await getCurrentStep();
      setCurrentStep(step);
    };
    loadCurrentStep();
  }, []);

  const goToNextStep = () => {
    let nextStep;
    if (currentStep === "step1") {
      nextStep = "step4";
    } else {
      nextStep = null;
    }
    setCurrentStep(nextStep);

    if (nextStep === null) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <div>
      {currentStep === "step1" && <SignUpStep1 onNextStep={goToNextStep} />}
      {currentStep === "step4" && <SignUpStep4 onNextStep={goToNextStep} />}
      {/* redirect to home page if currentStep is null, use react router */}
      {/* Render other step components based on currentStep */}
    </div>
  );
};

const FlowWrapper: React.FC = () => {
  // if there is an accountEmail in local storage, render LoginFlowWrapper else render SignUpFlowWrapper
  let userWasPreviouslyCreated = localStorage.getItem(
    "userWasPreviouslyCreated"
  );

  return (
    <div>
      {userWasPreviouslyCreated ? <LoginFlowWrapper /> : <SignUpFlowWrapper />}
    </div>
  );
};

//export default SignUpFlowWrapper;
export default FlowWrapper;
