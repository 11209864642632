import React from "react";
import { Button } from "@mui/material";
import FlightIcon from "@mui/icons-material/Flight";

interface NextButtonProps {
  onClick?: () => void; // Optional onClick handler if you need it for non-form usage
  disabled?: boolean; // Optional disabled prop if you need it for non-form usage
}

const NextButton: React.FC<NextButtonProps> = ({
  onClick,
  disabled = false,
}) => {
  return (
    <Button
      type="submit"
      variant="contained"
      fullWidth
      onClick={onClick} // This can be omitted if always used within a form
      disabled={disabled}
    >
      <FlightIcon style={{ transform: "rotate(90deg)" }} />
    </Button>
  );
};

export default NextButton;
