import React, { useState } from "react";
import {
  Container,
  Typography,
  Snackbar,
  Alert,
  useMediaQuery,
  useTheme,
} from "@mui/material";

interface SignUpStepLayoutProps {
  title: string;
  narrowTitle?: string;
  whisper?: React.ReactNode;
  children: React.ReactNode;
  snackbarMessage?: string;
  snackbarSeverity?: "error" | "warning" | "info" | "success";
}

const SignUpStepLayout: React.FC<SignUpStepLayoutProps> = ({
  title,
  narrowTitle,
  whisper,
  children,
  snackbarMessage,
  snackbarSeverity,
}) => {
  const theme = useTheme();
  const isNarrowScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  React.useEffect(() => {
    if (snackbarMessage) {
      setSnackbarOpen(true);
    }
  }, [snackbarMessage]);

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  title = isNarrowScreen ? (narrowTitle ? narrowTitle : title) : title;

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: isNarrowScreen ? "auto" : "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: isNarrowScreen ? "flex-start" : "center",
        alignItems: "center",
        marginTop: isNarrowScreen ? "2em" : "0",
      }}
    >
      <Typography
        variant="h4"
        sx={{ fontFamily: "Open Sans, sans-serif", mb: 3, textAlign: "center" }}
      >
        {title}
      </Typography>
      {children}

      {whisper && (
        <Typography
          variant="body2"
          sx={{ color: "gray", mt: 2, textAlign: "center" }}
        >
          {whisper}
        </Typography>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SignUpStepLayout;
