// getCareerProgress.ts

// Define your onClick functions here
const playSimulator = () => {
  document.location.href = "/simulator";
  console.log("Play Simulator Clicked");
};
const goToLeaderboard = () => {
  console.log("Go to Leaderboard");
};

export default function getHeaderText() {
  return {
    currentStep: 0,
    steps: [
      {
        title: "Welcome!",
        explainer:"Start your journey with the Collision Course Simulator and climb the leaderboard."   ,   
        linkText: "Get started!",
        onClick: playSimulator,
      },
      {
        title: "You're doing great!",
        explainer: "Boost your score by continuing to play every day.",
        linkText: "Check out the leaderboard",
        onClick: goToLeaderboard,
      },]
  };
}
