export const apiClient = async (url: URL | string, body: {} | null, method: string) => {
    var returnData = null
    var beginURL = "https://birdwatch.club/"
    let url_prefix = "https://roger-that-e17b66fe09c9.herokuapp.com/";
    if (process.env.NODE_ENV === "test") {
        url_prefix = "http://127.0.0.1:8000/";
    }   
    const refreshUrl = url_prefix + "api/token/refresh/";

    if(method === 'GET'){ 
        try {
            const response = await fetch(url, {
                method: method,
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('atcAccess')
                },
            });

            if (response.ok) {
                console.log('apiClient worked!')
                returnData = await response.json()
            }
            // if the response was a 401 try to refresh the token and try again
            if (response.status === 401) {

                console.log('401 one')
                console.log(localStorage.getItem("atcRefresh"))
                const refreshToken = localStorage.getItem("atcRefresh")
                const refreshResponse = await fetch(refreshUrl, {
                    method: "POST",
                    headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("atcRefresh"),
                    },
                    body: JSON.stringify({ refresh: localStorage.getItem("atcRefresh") }),
                });
                // add the new access token to local storage
                const refreshResponseJson = await refreshResponse.json();
                
                if (response.status === 401) {
                    console.log('401 two')
                    console.log(localStorage.getItem("atcRefresh"))
                    localStorage.removeItem("atcAccess")
                    localStorage.removeItem("atcRefresh")
                    console.log('removed token')
                }

            if (refreshResponse.status === 200) {
                console.log(localStorage.getItem("atcRefresh"))
                localStorage.setItem("atcAccess", refreshResponseJson.access);
                localStorage.setItem("atcRefresh", refreshToken ? refreshToken : "")
            }
            }
            
        
            }catch (error) {
            console.error('An error occurred:', error);
        }
    }
    else
    {
        try {
            const response = await fetch(url, {
              method: method,
              headers: {
                'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('atcAccess')
              },
              body: JSON.stringify(body)
            });
            if (response.ok) {
                console.log('apiClient worked!')
                returnData = await response.json()
            }

            // if the response was a 401 try to refresh the token and try again
            if (response.status === 401) {
                const refreshResponse = await fetch(refreshUrl, {
                    method: "POST",
                    headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("atcRefresh"),
                    },
                    body: JSON.stringify({ refresh: localStorage.getItem("atcRefresh") }),
                });
                // add the new access token to local storage
                const refreshResponseJson = await refreshResponse.json();
                
            if (refreshResponse.status === 200) {
                localStorage.setItem("atcAccess", refreshResponseJson.access);
            }
            }
            } catch (error) {
              console.error('An error occurred:', error);
            }
    }

    return(returnData)};
  