import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import SideMenu from "../components/SideMenu";
import MainContent from "../components/MainContent";
import { useIntercom } from "react-use-intercom";
import { getProfile } from "../api/getProfile";

const Home = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(0);

  const { boot, shutdown, hide, show, update } = useIntercom();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMenuClick = (index: number) => {
    setSelectedMenu(index);
    setDrawerOpen(!drawerOpen); // this line will close drawer when a menu item is clicked
  };

  let email = localStorage.getItem("accountEmail");
  const name = localStorage.getItem("accountUsername");

  async function goodStuff() {
    if (null === email) {
      let profileInfo = await getProfile();
      email = profileInfo.email;
      localStorage.setItem("accountEmail", email);
    }
    boot({
      email: email,
    });
  }

  useEffect(() => {
    goodStuff();
  }, []);

  return (
    <div>
      <NavBar toggleDrawer={toggleDrawer} />
      <SideMenu
        open={drawerOpen}
        toggleDrawer={toggleDrawer}
        handleMenuClick={handleMenuClick}
      />
      <MainContent selectedMenu={selectedMenu} />
    </div>
  );
};

export default Home;
