// CareerProgressBar.tsx

import React from "react";
import { Box, LinearProgress, Typography, Button } from "@mui/material";
import getCareerProgress from "../api/getHeaderText";
import getHeaderText from "../api/getHeaderText";

const HeaderBar = () => {
  const { currentStep, steps } = getHeaderText();
  const { title, explainer, linkText, onClick } = steps[currentStep];

  return (
    <Box
      sx={{
        width: "100%",
        marginBottom: "1em",
        backgroundColor: "#121212", // lighter background
        borderRadius: "10px", // rounded edges
        padding: "1em", // internal spacing
      }}
    >
      <Typography variant="caption" component="div" color="text.secondary">
        {title}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={(currentStep / (steps.length - 1)) * 100}
        sx={{ marginBottom: "1em" }} // add margin
      />
      <Typography variant="body2">{explainer}</Typography>
      <Box sx={{ textAlign: "center", marginTop: "1em" }}>
        <Button variant="text" onClick={onClick} color="primary">
          {linkText}
        </Button>
      </Box>
    </Box>
  );
};

export default HeaderBar;
