import React, { useState } from "react";
import { Box, TextField, Grid } from "@mui/material";
import SignUpStepLayout from "./SignUpStepLayout";
import SignUpNextButton from "../forms/SignUpNextButton";
import { saveStepData, StepData } from "./signUpApi";

interface SignUpStep5Props {
  onNextStep: () => void;
}

const SignUpStep5: React.FC<SignUpStep5Props> = ({ onNextStep }) => {
  const [username, setUsername] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSubmit = () => {
    if (false) {
      setSnackbarMessage("Please enter a valid email address.");
      return;
    }
    setSnackbarMessage("");
    const data: StepData = {
      username: username,
    };
    saveStepData('5', data)
    onNextStep();
  };

  return (
    <SignUpStepLayout
      title="Alright -- now the fun stuff"
      whisper="Enter a username so that you can compare your scores with other players."
      snackbarMessage={snackbarMessage}
      snackbarSeverity="error"
    >
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        sx={{ width: "100%" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Username"
              variant="standard"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <SignUpNextButton />
          </Grid>
        </Grid>
      </Box>
    </SignUpStepLayout>
  );
};

export default SignUpStep5;
