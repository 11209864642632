import React, { useState, useRef, useEffect } from "react";
import { Grid, TextField, Box } from "@mui/material";
import Link from "@mui/material/Link";
import SignUpStepLayout from "./SignUpStepLayout";
import formatPhoneNumber from "../../utils/formatters/FormatPhoneNumber";
import TwoFactorCodeInput from "../forms/TwoFactorCodeInput";
import { saveStepData, StepData } from "./signUpApi";

interface SignUpStep3Props {
  onNextStep: () => void;
}

const SignUpStep3: React.FC<SignUpStep3Props> = ({ onNextStep }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState(new Array(4).fill(""));
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [whisper, setWhisper] = useState<React.ReactNode>(null);

  const firstCodeInputRef = useRef<HTMLInputElement>(null); // Reference to the first input of the TwoFactorCodeInput

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    setPhoneNumber(formattedPhoneNumber);
    const data: StepData = {
      phone_number: formattedPhoneNumber
    };
  };

  const handleSubmit = () => {
    const data: StepData = {
      phone_number: phoneNumber,
    };
    saveStepData('3', data)
    onNextStep();
  };

  useEffect(() => {
    if (phoneNumber.length === 16) {
      handleSubmit()
      onNextStep();
    } else {
      setWhisper(null);
    }
  }, [phoneNumber]);

  return (
    <SignUpStepLayout
      title="How can we contact you?"
      whisper={whisper}
      snackbarMessage={snackbarMessage}
      snackbarSeverity="error"
    >
      <Box
        component="form"
        onSubmit={(e) => e.preventDefault()}
        sx={{ width: "100%" }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <TextField
              label="Phone Number"
              variant="standard"
              fullWidth
              value={phoneNumber}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Box>
    </SignUpStepLayout>
  );
};

export default SignUpStep3;
