import React, { useState } from "react";
import { Box, TextField, Grid } from "@mui/material";
import SignUpStepLayout from "./SignUpStepLayout";
import SignUpNextButton from "../forms/SignUpNextButton";
import { validateEmail } from "../../utils/validators/email";
import { saveStepData, StepData } from "./signUpApi";

interface SignUpStep1Props {
  onNextStep: () => void;
}

const SignUpStep1: React.FC<SignUpStep1Props> = ({ onNextStep }) => {
  const [email, setEmail] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  async function handleSubmit() {
    if (!validateEmail(email)) {
      setSnackbarMessage("Please enter a valid email address.");
      return;
    }
    setSnackbarMessage("");
    const data: StepData = {
      email: email,
      password: "pw",
    };
    await saveStepData("1", data);
    await localStorage.setItem("accountEmail", email);
    onNextStep();
  }

  return (
    <SignUpStepLayout
      title="We're glad you're here. Let's start with the basics."
      whisper="We won't share your email with anyone without your permission."
      snackbarMessage={snackbarMessage}
      snackbarSeverity="error"
    >
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        sx={{ width: "100%" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Email"
              variant="standard"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <SignUpNextButton />
          </Grid>
        </Grid>
      </Box>
    </SignUpStepLayout>
  );
};

export default SignUpStep1;
