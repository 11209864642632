import { apiClient } from "./apiClient";

export const getProfile = async (): Promise<{
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  userName: string;
}> => {
  let url_prefix = "https://roger-that-e17b66fe09c9.herokuapp.com/";
  if (process.env.NODE_ENV === "test") {
    url_prefix = "http://127.0.0.1:8000/";
  }
  var url = url_prefix + "apiapp/users/me/";
  var responseData = await apiClient(url, null, "GET");

  return {
    firstName: responseData.first_name,
    lastName: responseData.last_name,
    email: responseData.email,
    phoneNumber: responseData.phone_number,
    userName: responseData.username,
  };
};
