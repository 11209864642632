import React, { useState, useEffect } from "react";
import { getProfile } from "../api/getProfile";
import UserProfile from "../types/UserProfile";
import {
  Button,
  Card,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { CustomThemeProvider } from "../theme";
// save icon
import SaveIcon from "@mui/icons-material/Save";

function Profile() {
  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState<UserProfile | null>(null);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    async function fetchProfile() {
      const data = await getProfile();
      setUserInfo(data);
      setIsLoading(false);
    }
    fetchProfile();
  }, []);

  if (isLoading) return <div>Loading...</div>;

  return (
    <CustomThemeProvider>
      <Box
        sx={{
          width: "100%",
          marginBottom: "1em",
          backgroundColor: "#121212", // lighter background
          borderRadius: "10px", // rounded edges
          padding: "1em", // internal spacing
        }}
      >
        <Card variant="outlined" sx={{ padding: 2 }}>
          <Box display="flex" justifyContent="space-between" alignItems="start">
            <Typography gutterBottom variant="h5" component="div">
              {userInfo?.firstName} {userInfo?.lastName}
            </Typography>
          </Box>
            <>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ marginBottom: 2 }}
              >
                Email: {userInfo?.email}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ marginBottom: 2 }}
              >
                Phone Number: {userInfo?.phoneNumber}
              </Typography>
              
            </>
        </Card>
      </Box>
    </CustomThemeProvider>
  );
}

export default Profile;
