import React, { useState } from "react";
import { Grid, TextField, Box } from "@mui/material";
import SignUpStepLayout from "./SignUpStepLayout";
import SignUpNextButton from "../forms/SignUpNextButton";
import { saveStepData, StepData } from "./signUpApi";

const SignUpStep2 = ({ onNextStep }: { onNextStep: () => void }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!firstName || !lastName) {
      setSnackbarMessage("Please fill in all fields."); // Set the Snackbar message
      return;
    }
    const data: StepData = {
      first_name: firstName,
      last_name: lastName,
    };
    saveStepData('2', data)
    // Proceed to the next step
    console.log("Submitted:", firstName, lastName);
    onNextStep();
  };

  const title = "Can we get your name?";

  return (
    <SignUpStepLayout
      title={title}
      snackbarMessage={snackbarMessage}
      snackbarSeverity="warning"
      whisper={
        "Air Traffic Controllers earn more than 85% of American wage earners"
      }
    >
      <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="First Name"
              variant="standard"
              fullWidth
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSubmit(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Last Name"
              variant="standard"
              fullWidth
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSubmit(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <SignUpNextButton />
          </Grid>
        </Grid>
      </Box>
    </SignUpStepLayout>
  );
};

export default SignUpStep2;
