import React from "react";
import ReactMarkdown from "react-markdown";
import FAQMarkdown from "./FAQ.md";

function FAQ() {
  const [markdown, setMarkdown] = React.useState("");

  // Fetch FAQ markdown data when component mounts
  React.useEffect(() => {
    fetch(FAQMarkdown)
      .then((response) => response.text())
      .then((text) => {
        setMarkdown(text);
      });
  }, []);

  return <ReactMarkdown children={markdown} />;
}

export default FAQ;
