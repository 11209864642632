import React from "react";
import { Grid, TextField, Box } from "@mui/material";

interface TwoFactorCodeInputProps {
  code: string[];
  onCodeChange: (code: string[]) => void;
  firstCodeInputRef?: React.RefObject<HTMLInputElement>;
}

const TwoFactorCodeInput: React.FC<TwoFactorCodeInputProps> = ({
  code,
  onCodeChange,
  firstCodeInputRef,
}) => {
  const handleCodeChange = (element: EventTarget, index: number) => {
    const inputElement = element as HTMLInputElement;
    let newCode = [...code];
    newCode[index] = inputElement.value.toUpperCase();
    onCodeChange(newCode);

    // Automatically move to the next input field if the current one is filled
    if (index < code.length - 1 && inputElement.value) {
      const nextSibling = document.getElementById(
        `codeBox-${index + 1}`,
      ) as HTMLInputElement | null;
      nextSibling?.focus();
    }
  };

  const handleBackspace = (element: EventTarget, index: number) => {
    const inputElement = element as HTMLInputElement;
    if (inputElement.value === "" && index > 0) {
      const previousSibling = document.getElementById(
        `codeBox-${index - 1}`,
      ) as HTMLInputElement | null;
      previousSibling?.focus();
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={1} justifyContent="center">
        {code.map((num, index) => (
          <Grid key={index} item>
            <TextField
              inputRef={index === 0 ? firstCodeInputRef : undefined} //
              inputProps={{
                maxLength: 1,
                style: { textTransform: "uppercase", textAlign: "center" },
              }}
              variant="outlined"
              value={num}
              onChange={(e) => handleCodeChange(e.target, index)}
              onKeyDown={(e) =>
                e.key === "Backspace" && handleBackspace(e.target, index)
              }
              sx={{ width: 60, height: 60, "& input": { fontSize: "1.5rem" } }}
              id={`codeBox-${index}`}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TwoFactorCodeInput;
