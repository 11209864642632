import { createTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import React, { ReactNode, useMemo } from "react";

type ThemeOptions = {
  children: ReactNode;
};

// Define a common typography object to be used in both light and dark themes
const typography = {
  fontFamily: "Open Sans, Arial, sans-serif",
  button: {
    fontFamily: "Roboto, Arial, sans-serif", // Use Roboto for buttons
    textTransform: "none" as const, // Remove uppercase styling
    fontWeight: 500, // Adjust as needed
  },
  // Add other typography settings if needed
};

// Create light theme
const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#a68b6d",
    },
    secondary: {
      main: "#6e564b",
    },
    background: {
      default: "#f5f5f5",
    },
  },
  typography: typography,
});

// Create dark theme
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#6e564b",
    },
    secondary: {
      main: "#a68b6d",
    },
    background: {
      default: "#1c1c1c",
    },
  },
  typography: typography,
});

// Custom ThemeProvider Component
export const CustomThemeProvider = ({ children }: ThemeOptions) => {
  //const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const prefersDarkMode = true; // TODO: non-dark mode is busted right now

  const theme = useMemo(() => {
    return prefersDarkMode ? darkTheme : lightTheme;
  }, [prefersDarkMode]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
