import React, { useState, useRef, useEffect } from "react";
import { Grid, TextField, Box } from "@mui/material";
import Link from "@mui/material/Link";
import SignUpStepLayout from "./SignUpStepLayout";
import formatPhoneNumber from "../../utils/formatters/FormatPhoneNumber";
import TwoFactorCodeInput from "../forms/TwoFactorCodeInput";
import { saveStepData, StepData} from "./signUpApi";

interface SignUpStep4Props {
  onNextStep: () => void;
}

const SignUpStep4: React.FC<SignUpStep4Props> = ({ onNextStep }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState(new Array(4).fill(""));
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [whisper, setWhisper] = useState<React.ReactNode>(null);

  const firstCodeInputRef = useRef<HTMLInputElement>(null); // Reference to the first input of the TwoFactorCodeInput

  useEffect(() => {
    if (code.every((num) => num !== "")) {
      const formattedCode = code.join('')
      const data: StepData = {
        code: formattedCode,
      };
      saveStepData('4', data);
      onNextStep();
    }
  }, [code, saveStepData]);

 
  return (
    <SignUpStepLayout
      title="Please enter the 4 digit code here."
      whisper={whisper}
      snackbarMessage={snackbarMessage}
      snackbarSeverity="error"
    >
      <Box
        component="form"
        onSubmit={(e) => e.preventDefault()}
        sx={{ width: "100%" }}
      >
        <Grid container spacing={2} justifyContent="center">
            <TwoFactorCodeInput
              code={code}
              onCodeChange={setCode}
              firstCodeInputRef={firstCodeInputRef}
            />
        </Grid>
      </Box>
    </SignUpStepLayout>
  );
};

export default SignUpStep4;
