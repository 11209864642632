import React from "react";
import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import sideMenuItems from "../config/sideMenuItems";

interface SideMenuProps {
  open: boolean;
  toggleDrawer: () => void;
  handleMenuClick: (index: number) => void;
}

function goToPath(path: string) {
  window.location.href = path;
}

const SideMenu: React.FC<SideMenuProps> = ({
  open,
  toggleDrawer,
  handleMenuClick,
}) => {
  return (
    <Drawer anchor="left" open={open} onClose={toggleDrawer}>
      <List>
        {sideMenuItems.map((content, index) => (
          <ListItem
            button
            key={content.title}
            onClick={() => content.component ? handleMenuClick(index) : goToPath(content.path)}
          >
            <ListItemText primary={content.title} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default SideMenu;
